@font-face {
  font-family: 'SourceSansPro-Black';

  src: local('SourceSansPro-Black'),
    url(./assets/fonts/SourceSansPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Bold';

  src: local('SourceSansPro-Bold'),
    url(./assets/fonts/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Regular';

  src: local('SourceSansPro-Regular'),
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';

  src: local('SourceSansPro-SemiBold'),
    url(./assets/fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'SFMono-Regular';

  src: local('SFMono-Regular'),
    url(./assets/fonts/FontsFree-Net-SFMono-Regular.ttf) format('truetype');
}

@media (min-width: 768px) and (max-width: 991px) {
  .responsive-card {
    grid-template-columns: repeat(2, 1fr) !important;
    width: 60%;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .responsive-card {
    grid-template-columns: repeat(2, 1fr) !important;
    width: 60%;
  }
}

@media (max-width: 600px) {
  .responsive-card {
    grid-template-columns: repeat(1, 1fr) !important;
    width: 30%;
  }
}

.w-100 {
  width: 100%;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #fff
}

::-webkit-scrollbar-thumb {
  background: #A4C5E8;
  border-radius: 4px;
}